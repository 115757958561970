import { Geo } from '@getvim/atomic-ui';
import { trim } from 'lodash-es';
import { Address } from '../types';
import { capitalizeAsName } from './general';

export default function addressFormat(address: Partial<Address>): string {
  if (!address) {
    return '';
  }

  const stringAddress = [
    ...[address.address1, address.address2, address.city].map(
      (text) => text && capitalizeAsName(text),
    ),
    ...[address.state],
  ]
    .filter(Boolean)
    .join(', ');
  return [stringAddress, address.zipCode].filter(Boolean).join(' ');
}

const formatters: Record<number, (addressObj: string[], zipCode?: string) => Address> = {
  1: (_, zipCode) => ({
    zipCode,
  }),
  2: (addressObj, zipCode) => {
    const stateAndZip = addressObj?.[0];
    const hasZipInState = zipCode && stateAndZip?.includes(zipCode);
    const state = hasZipInState ? stateAndZip?.split(' ')[0] : stateAndZip; // might need to change this to state codes

    return {
      state,
      zipCode,
    };
  },
  3: (addressObj, zipCode) => {
    const stateAndZip = addressObj?.[1];
    const hasZipInState = zipCode && stateAndZip?.includes(zipCode);
    const state = hasZipInState ? stateAndZip?.split(' ')[0] : stateAndZip;

    return {
      city: addressObj?.[0],
      state,
      zipCode,
    };
  },
  4: (addressObj, zipCode) => {
    const stateAndZip = addressObj?.[2];
    const hasZipInState = zipCode && stateAndZip?.includes(zipCode);
    const state = hasZipInState ? stateAndZip?.split(' ')[0] : stateAndZip;

    return {
      address1: addressObj?.[0],
      city: addressObj?.[1],
      state,
      zipCode,
    };
  },
};

export const formatGeoToAddress = (geo?: Geo | null): Address | undefined => {
  if (!geo?.address) return undefined;

  const addressObj = geo.address?.split(',').map((item) => trim(item));

  const zipCode = geo.zip;
  const address = formatters[addressObj.length](addressObj, zipCode);

  return address;
};
